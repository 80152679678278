const config = {
    hostLocally: false,
    localhost: "http://localhost:4000",
    serverhost: "https://api.sprocketstats.io",
};

var url = null;

if (config.hostLocally) {
    url = config.localhost
} else {
    url = config.serverhost
}

console.log(url)
export default url