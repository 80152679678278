import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from "chart.js";
// import { Bar, Doughnut, Line } from "react-chartjs-2"; // not using graphs this year i think i can't figure it out lol

interface Sums {
  [key: string]: number;
}

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

//This page could be done with loops but I am too fucking lazy and my brain is fried before comp
//Absolutely redo this page next year
function TeamInfo(props) {
  const [proportions, setProportions] = useState<Sums>({});
  const [averages, setAverages] = useState<Sums>({});
  const [list, setList] = useState<
    Array<{
      key: number;
      value: number;
    }>
  >([]);

  const [comments, setComments] = useState([]);
  const [collection, setCollection] = useState(null);

  const [doughnutLabel, setDLabel] = useState([]);

  const [dataType, setDataType] = useState(""); //On change components
  let matchNumber: number[] = [0, 12]; //used to test if chart works, you may deleted these

  var data = {
    //data input for the line charts
    labels: matchNumber, //x values, 1D array fitable
    datasets: [
      {
        label: "data",
        backgroundColor: "#d89ce4",
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  };
  var dataDoughnut = {
    //data input for the doughnut charts
    labels: doughnutLabel,
    datasets: [
      {
        label: "data",
        data: [2, 9], //Variables in the doughnut chart,an 1D array is fitable.
        backgroundColor: ["#d89ce4", "#88a4e2"],
        borderColor: ["black", "#d89ce4"],
        borderWidth: 1,
      },
    ],
  };

  var options = { aspectRatio: 2 }; //size of the graphs, used by both line and doughnut chart

  let handler = {
    get: function (target, name) {
      return target.hasOwnProperty(name) ? target[name] : 0;
    },
  };
  let stats = {};
  useEffect(() => {
    getTeamStats();
    console.log(props.pitEntry);
  }, []);

  function handleChange(e) {
    setDataType(e.target.value);
    console.log(dataType); //The variable dataType gets whatever the user choose in the dropdown, update eachtime the user make changes to the dropdown
    // console.log(user);
  }

  function getTeamStats() {
    let tempSum = {};
    let tempRates = {};
    let tempLists = [{}, {}]; //Casey made this function which fried my head
    let tempComments = [];

    let rates = new Proxy(tempRates, handler);
    let sums = new Proxy(tempSum, handler);
    let lists = new Proxy(tempLists, handler);
    props.matchEntries.forEach((entry) => {
      Object.keys(entry.fields).forEach((field) => {
        // console.log(entry.fields[field]);
        // console.log(entry.fields); entire schema
        if (
          typeof entry.fields[field] === "object" &&
          entry.fields[field] != null
        ) {
          Object.keys(entry.fields[field]).forEach((option) => {
            if (typeof entry.fields[field][option] === "number") {
              sums[field + option] = entry.fields[field][option];
            } else {
              sums[field + option] = 1;
            }
          });
        } else if (typeof entry.fields[field] === "number") {
          sums[field] = entry.fields[field];
        }
      });
    });
    let entryFields = props.matchEntries
      .map((entry) => {
        return entry.fields;
      })
      .filter(
        (obj) => obj.matchType !== "Practice" && !obj.markForReview.review
      );
    console.log(entryFields);
    entryFields.forEach((entry) => {
      rates["Left Community"] +=
        entry["exitCommunityAuton"] === "Yes" || entry["leftTarmac"] === "Yes\n" //IDK what these parts are for
          ? 1
          : 0;
      rates["onStage"] +=
        entry["onStage"] === "Attempted" || entry["onStage"] === "Attempted\n" // backend fix joanna or else
          ? 1
          : 0;

      rates["collectOrNo"] +=
        entry["collectOrNo"] === "Yes" || entry["collectOrNo"] === "Yes\n"
          ? 1
          : 0;

      rates["robotDefenseOrNo"] +=
        entry["robotDefenseOrNo"] === "Yes" ||
        entry["robotDefenseOrNo"] === "No\n"
          ? 1
          : 0;

      tempComments.push(entry.comments);
      doughnutLabel.push(entry.scoreOrNo);
    });
    Object.keys(rates).forEach((i) => {
      rates[i] /= entryFields.length;
    });
    Object.keys(sums).forEach((i) => {
      sums[i] /= entryFields.length;
    });
    setProportions(rates);
    setAverages(sums);
    setList(lists);
    setComments(tempComments);
    if (sums.collectionAreafloor > 0 && sums.collectionAreasource > 0)
      setCollection("Floor and Source");
    else if (sums.collectionAreafloor > 0) setCollection("Floor");
    else if (sums.collectionAreasource > 0) setCollection("Source");
    else setCollection("N/A");
    // rates is basically finding percentages of backend data
    // averages is the average per match of backend data (total of every match / number of matches)
    console.log("rates ", rates);
    console.log("sums ", sums);
    console.log(lists);
  }

  return (
    <Grid className="py-5">
      <hr />
      <Typography variant="h4">Team {props.teamNumber}</Typography>
      <br />

      <Typography variant="h5">Average Stats</Typography>
      <br></br>
      <Typography variant="h5">Auton</Typography>

      <Typography>
        {" "}
        Notes Scored :{" "}
        {averages.ampNotesAutonsuccess + averages.speakerNotesAutonsuccess}
      </Typography>
      <Typography>
        {" "}
        Scoring Success Rate :{" "}
        {Math.floor(
          100 *
            ((averages.ampNotesAutonsuccess +
              averages.speakerNotesAutonsuccess) /
              (averages.ampNotesAutonsuccess +
                averages.speakerNotesAutonsuccess +
                averages.ampNotesAutonfail +
                averages.speakerNotesAutonfail))
        ) + "%"}
      </Typography>
      <Typography>
        {" "}
        Speaker Instead of Amp Rate :{" "}
        {Math.floor(
          100 *
            ((averages.ampNotesAutonsuccess + averages.ampNotesAutonfail) /
              (averages.ampNotesAutonsuccess +
                averages.speakerNotesAutonsuccess +
                averages.ampNotesAutonfail +
                averages.speakerNotesAutonfail))
        ) + "%"}
      </Typography>

      {/* {displayAutonGrid} */}

      {/* Everything below may be changed according to what data is needed */}
      <hr />
      <Typography variant="h5">Teleop</Typography>
      <Typography>
        {" "}
        Notes Scored :{" "}
        {averages.ampNotesTeleopsuccess + averages.speakerNotesTeleopsuccess}
      </Typography>
      <Typography>
        {" "}
        Scoring Success Rate :{" "}
        {Math.floor(
          100 *
            ((averages.ampNotesTeleopsuccess +
              averages.speakerNotesTeleopsuccess) /
              (averages.ampNotesTeleopsuccess +
                averages.speakerNotesTeleopsuccess +
                averages.ampNotesTeleopfail +
                averages.speakerNotesTeleopfail))
        ) + "%"}
      </Typography>
      <Typography>
        {" "}
        Speaker Instead of Amp Rate :{" "}
        {Math.floor(
          100 *
            ((averages.ampNotesTeleopsuccess + averages.ampNotesTeleopfail) /
              (averages.ampNotesTeleopsuccess +
                averages.speakerNotesTeleopsuccess +
                averages.ampNotesTeleopfail +
                averages.speakerNotesTeleopfail))
        ) + "%"}
      </Typography>
      <Typography> Amplifications : {averages.boostNumamplified}</Typography>

      <hr />

      <Typography variant="h5">Endgame</Typography>
      <Typography>
        {" "}
        Get on Stage Rate : {Math.floor(100 * proportions.onStage) + "%"}{" "}
      </Typography>

      <hr />

      <Typography variant="h5">Match Review</Typography>
      <Typography>
        Did the Robot Collect Cargo :{" "}
        {Math.floor(100 * proportions.collectOrNo) + "%"}
      </Typography>
      <Typography> Collect From : {collection} </Typography>

      <Typography>Driver Skill : {averages.driverSkill}</Typography>
      <Typography>Robot Speed : {averages.robotSpeed}</Typography>
      <Typography>
        Did the Robot play Defense :{" "}
        {Math.floor(100 * proportions.robotDefenseOrNo) + "%"}
      </Typography>
      <Typography>Defense : {averages.defense}</Typography>
      <Typography>Comments : </Typography>
      {comments.map((comment) => (
        <div>
          <Typography>{comment + "\n"} </Typography>
          <br />
        </div>
      ))}
      <hr />

      {/* <Typography variant="h5">Robot Info</Typography>
      {props.pitEntry.length > 0 ? (
        <img
          src={props.pitEntry[0].imageLink}
          style={{ width: 300, float: "left" }}
        ></img>
      ) : (
        <Typography>No image found</Typography>
      )}
      <FormControl fullWidth>
        <InputLabel id="lineChartData">Data Type</InputLabel>
        <Select
          labelId="lineChartData"
          id="lineChartData"
          value={dataType}
          label="Data Type"
          onChange={handleChange}
        >
          {/* The dropdown components, you can change the "twenty" to whatever types of graph you want */}
      {/* <MenuItem value={"Match Type"}>Match Type</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl> */}
      {/* Line Chart components */}
      {/* <div><Line id="choice" data={data} options={options}></Line></div> */}
      {/* Doughnut chart componenets */}
      <div>
        {/* <Doughnut data={dataDoughnut} options={options}></Doughnut> */}
      </div>
    </Grid>
  );
}

export default TeamInfo;
