//imports for getting data from backend
import {
  TextField,
  Grid,
  Typography,
  InputAdornment,
  Button,
  ButtonGroup,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import CircleLoader from "../components/CircleLoader";
import TeamInfo from "../components/TeamInfo";
import Multiple from "../components/Multiple";
import { useUserStore } from "../state/useUserStore";
import "./Dashboard.css";
import { idID } from "@mui/material/locale";
import url from "../config.js";

var currentSheet = undefined;

function Dashboard() {
  // const that were alr in TeamData
  const [matchData, setMatchData] = useState([]);
  const [pitData, setPitData] = useState([]);
  const [teamNumber, setTeamNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [teamComponent, setTeamComponent] = useState(<div></div>);
  const [error, setError] = useState(false);
  const { firstName, lastName, seed, userName, email } =
    useUserStore.getState();

  useEffect(() => {}, []);

  function onSubmit() {
    console.log("123123");
    localStorage.clear();
    window.location.href = "/";
  }

  // function showhide(id) {
  //   var e = document.getElementById(id);
  //   e.style.display = (e.style.display == 'block') ? 'none' : 'block';
  // }

  function switchingSheets(sheet) {
    var sheet1 =
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vSR484CKX1lRmclu_0gwT1kA1JesKkhoOUe2zpkRSg8C1QLKYHd5S3hvcpTr38c6T-0ZE0JBPwXjhM4/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false";
    var sheet2 =
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vSR484CKX1lRmclu_0gwT1kA1JesKkhoOUe2zpkRSg8C1QLKYHd5S3hvcpTr38c6T-0ZE0JBPwXjhM4/pubhtml?gid=248953152&amp;single=true&amp;widget=true&amp;headers=false";
  }

  function changeImage() {
    var img = document.getElementById("sheets");
    //img.src="sheets/test2";
    return false;
  }

  function getTeam() {
    let config = {
      headers: {
        "x-auth-token": localStorage.getItem("jwt"),
      },
    };
    let matchEntries = [];
    let pitEntry = {};
    // axios
    //   .get(
    //     "http://localhost:4000/api/v1/datasets/datasetId/635f3325d8d60836449c42ac/teamNumber/${teamNumber}",
    //     //`https://backend2022-ss-production.up.railway.app/api/v1/datasets/datasetId/635f3325d8d60836449c42ac/teamNumber/${teamNumber}`,
    //     //`https://sprocket-stats-backend-jq1wpfkny-lte24s-projects.vercel.app/Backend-Server/routes/api/v1/datasets/datasetId/635f3325d8d60836449c42ac/teamNumber/${teamNumber}`,
    //     config
    //   )
    //   .then((res) => {
    //     pitEntry = res.data;
    //     console.log(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //   });
  }
  return (
    <div
      style={{
        borderBottom: "5px solid #ba95dc",
        marginLeft: "2%",
        paddingBottom: "5%",
      }}
    >
      <Typography
        variant="h1"
        style={{
          fontSize: "5em",
          lineHeight: "90%",
          fontWeight: "400",
          paddingBottom: "3%",
        }}
      >
        Hello, {firstName}
        !!
      </Typography>

      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        size="large"
      >
        <Button
          variant="contained"
          size="large"
          style={{
            backgroundColor: "#ba95dc",
            color: "#ffffff",
            width: "fit-content",
            height: "5%",
            fontSize: 28,
          }}
        >
          {/* <a className="dashlink" href="/dataentry/63cca858934160925900414b/63cca5ef93416092590040f9"> */}
          <a
            className="dashlink"
            href="/dataentry/63cca858934160925900414b/6620d586ea349596c3ae94a3"
          >
            <Typography
              variant="h2"
              style={{
                fontSize: 30,
                display: "flex",
                justifyContent: "center",
                color: "white",
                textDecorationColor: "white",
              }}
            >
              Match Scouting
            </Typography>
          </a>
        </Button>
        <Button
          variant="contained"
          size="large"
          style={{
            backgroundColor: "#ba95dc",
            color: "#ffffff",
            width: "fit-content",
            height: "5%",
            fontSize: 28,
          }}
        >
          <a
            className="dashlink"
            href="/dataentry/63cca86f934160925900414c/6620d5fdea349596c3ae94a4"
          >
            <Typography
              variant="h2"
              style={{
                fontSize: 30,
                display: "flex",
                justifyContent: "center",
                color: "white",
                textDecorationColor: "white",
              }}
            >
              Pit Scouting
            </Typography>
          </a>
        </Button>
      </ButtonGroup>

      <div
        style={{ borderBottom: "5px solid #ba95dc", paddingTop: "3%" }}
      ></div>
      <div
        style={{
          borderBottom: "5px solid #ba95dc",
          marginLeft: "0.3%",
          paddingBottom: "5%",
        }}
      >
        <Typography
          style={{
            fontSize: "3em",
            lineHeight: "90%",
            fontWeight: "400",
            paddingTop: "3%",
          }}
        >
          Information:
        </Typography>
        <iframe
          id="dashLink"
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQrP-YjV9oLkQRk9IznVWdprIIJlQJwfDotvXJc_vuVVO_RpCrKUtdaPt4ndeIof7725XpoCdwcydDE/pubhtml?widget=true&amp;headers=false"
          width="100%"
          height="600"
          frameBorder="0"
          style={{
            border: "2px",
            background: "transparent",
            paddingTop: "1.5%",
          }}
        ></iframe>
      </div>
    </div>
  );

  // ReactDOM.render(
  //   <HeatMap xLabels={xLabels} yLabels={yLabels} data={data} />,
  //   document.getElementById("app")
  // );

  // const options = {};

  // return (
  //   <div>
  //     {/* <Bar data={data} options={options}></Bar> */}
  //     {/* <HeatMap xLabels={xLabels} yLabels={yLabels} data={data} /> */}
  //     <GameGrid></GameGrid>
  //     {/* <Multiple></Multiple> */}
  //   </div>
  // );
}
export default Dashboard;
